import request from '@/utils/request'
import {isBlank} from "@/utils/whale";

// 查询参数列表
export function listConfig(query) {
  return request({
    url: '/system/config/list',
    method: 'get',
    params: query
  })
}

// 查询参数详细
export function getConfig(configId) {
  return request({
    url: '/system/config/' + configId,
    method: 'get'
  })
}

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return request({
    url: '/system/config/configKey/' + configKey,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/sys/type/' + dictType,
    method: 'get'
  })
}

//获取省市区三级联动列表
export function getProvince() {
  return request({
    url: '/sys/areaSelectList',
    method: 'get'
  })
}

export function getCity(province) {
  return request({
    url: '/sys/areaSelectList?province=' + province,
    method: 'get'
  })
}

export function getCounty(province, city) {
  return request({
    url: '/sys/areaSelectList?province=' + province + '&city=' + city,
    method: 'get'
  })
}