import request from "@/utils/request";
import { isBlank } from "@/utils/whale";
import { getToken } from "../../utils/auth";
import { cleanObject } from "@/utils/deepClone";

// 添加视图
export function addView(data) {
  return request({
    url: "/form-view",
    method: "post",
    data,
  });
}
// 获取视图列表
export function listViews(formId) {
  return request({
    url: `/form-view/${formId}`,
    method: "get"
  });
}
// 获取视图列表
export function unLoginListViews(formId) {
  return request({
    url: `/form-view/unLogin/${formId}`,
    method: "get"
  });
}
// 获取视图数据列表
export function unLoginViewData(viewId,data) {
  return request({
    url: `/form-view/list/unLogin/${viewId}`,
    method: "post",
    data
  });
}
// 获取视图数据列表
export function unlLoginFillDetail(fillId,formId,viewId) {
  return request({
    url: `/form-view/info/unLogin/${fillId}/${formId}/${viewId}`,
    method: "get"
  });
}
// 修改表单视图
export function editView(data) {
  return request({
    url: "/form-view",
    method: "put",
    data,
  });
}
// 查看视图详情
export function viewDetail(formViewId) {
  return request({
    url: `/form-view/detail/${formViewId}`,
    method: "get"
  });
}

// 删除表单视图
export function deleteView(formViewId) {
  return request({
    url: `/form-view/${formViewId}`,
    method: "delete"
  });
}
// 获取视图列表表头
export function formViewColumnDefs(formViewId) {
  return request({
    url: `/form-view/columnDefs/fieldTree/${formViewId}`,
    method: "get"
  });
}
// 获取视图表单表头
export function formViewFields(formViewId) {
  return request({
    url: `/form-view/fields/${formViewId}`,
    method: "get"
  });
}

/**
 * 根据表单视图ID和数据，提交表单视图数据
 * @param {string} formViewId - 表单视图的ID，用于指定具体的表单视图
 * @param {object} data - 要提交的数据对象
 * @returns 返回一个请求对象，通过这个请求可以获取表单视图的数据列表
 */
export function formViewData(formViewId,data){
  // 发送POST请求，获取表单视图数据列表
  return request({
    url: `/form-view/list/${formViewId}`,
    method: "post",
    data: data
  });
}


/**
 * 获取用户对于表单的身份
 * @param {string} formId - 表单的ID
 * @return {Promise} 返回一个Promise对象，成功时携带表单的唯一标识信息
 */
export function formIdentity(formId){
  // 发起GET请求获取指定表单的唯一标识
  return request({
    url: `form-view/identity/${formId}`,
    method: "get",
  });
}

