export const getFormPostObject = function () {
    console.log(this.$refs.vformDesigner.getFormJson());
    let fromPost = {
        projectId: this.projectId,
        formJson: this.$refs.vformDesigner.getFormJson(),
        formVer: 2,
        formId: this.isBlank(this.formId) ? 0 : this.formId
    };

    return fromPost;
}
export const setFormPostObject = function (formJson) {
    if (this.$refs.vformDesigner) {
        this.$refs.vformDesigner.setFormJson(formJson);
    } else {

        console.log("vform render not found ", this.$refs);
    }
}


export const renderForm = function (formJson) {
    if (this.$refs.vformRender) {
        this.$refs.vformRender.setFormJson(formJson);
    } else {
        console.log("vform render not found ");
    }
}

export const getFormData = function () {
    if (this.$refs.vformRender) {
        return this.$refs.vformRender.getFormData();
    } else {
        console.log("vform render not found ");
    }
}